import React, { useEffect } from 'react';
import Routes from 'routes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axios from 'axios';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { globalAction, dispatch } from 'store';
import axiosServices from 'utils/axios';

// ==============================|| APP ||============================== //

const App = () => {
    const firebaseConfig = {
        apiKey: 'AIzaSyD9ff4JL5dT2MIjxDQjfYOsfD_4JPcJfCA',
        authDomain: 'logical-crm.firebaseapp.com',
        projectId: 'logical-crm',
        storageBucket: 'logical-crm.appspot.com',
        messagingSenderId: '772023176711',
        appId: '1:772023176711:web:91a0bde43a82185211a4a2',
        measurementId: 'G-HX879EFC81'
    };

    const fapp = initializeApp(firebaseConfig);
    const messaging = getMessaging(fapp);
    const sendTokenToServer = async (fcmToken) => {
        try {
            const response = await axiosServices.post('/api/fcm-token', { fcmToken });
            console.log('Token sent to server: ', response.data);
        } catch (error) {
            console.error('Error sending token to server: ', error);
        }
    };
    async function requestPermission() {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                const token = await getToken(messaging, {
                    vapidKey: 'BFLJB9sNzz2f0247mvtCnZZwupWdkLrITbeiaTJjWMYb-dPKUhvwuNIkMLXyeHXtELYPM9h-Vj8EQEFj26nyM-s' // Ensure the key has no spaces or invalid characters
                });

                console.log('Token generated : ', token);

                // Send the token to your server
                await sendTokenToServer(token);
            } else if (permission === 'denied') {
                alert('You denied the notification');
            }
        } catch (error) {
            console.error('An error occurred while requesting permission or getting the token:', error);
        }
    }

    useEffect(() => {
        requestPermission();
    }, []);

    // onMessage(messaging, (payload) => {
    //     // if (payload?.notification) {
    //     dispatch(globalAction('SHOW_TOASTER', { status: 'success', message: payload?.notification?.title }));
    //     // }
    //     console.log('Message received. ', payload);
    // });

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
