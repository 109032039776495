import { useState, useCallback, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography, Divider, Link, useMediaQuery } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

// third-party
import OtpInput from 'react-otp-input-rc-17';
import { post } from 'utils/axiosRequests';
import useAuth from 'hooks/useAuth';

// ============================|| EMAil Verification||============================ //

const AuthEmailVerification = ({ email }) => {
    const theme = useTheme();
    const { verifyEmail } = useAuth();
    const [otp, setOtp] = useState();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [timer, setTimer] = useState(59);
    const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

    // useEffect(() => {
    //     if (timer > 0) setTimeout(timeOutCallback, 1000);
    // }, [timer, timeOutCallback]);

    useEffect(() => {
        if (otp?.length === 6) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [otp]);

    const resendOTP = function () {
        post('/api/users/send-otp', { email });

        // if (!timer) {
        //     setTimer(60);
        // }
    };

    const verifyInputOTP = (e) => {
        e.preventDefault();
        if (otp.length === 6) {
            verifyEmail(email, otp);
        }
    };
    const formateNumber = (n) => (n > 9 ? n : '0'.concat(n));

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const borderColor = theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300];

    return (
        <form onSubmit={(e) => verifyInputOTP(e)}>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ py: 2 }}>
                    <OtpInput
                        value={otp}
                        onChange={(otpNumber) => setOtp(otpNumber)}
                        numInputs={6}
                        containerStyle={{ justifyContent: 'space-between' }}
                        inputStyle={{
                            width: '100%',
                            margin: '8px',
                            padding: '10px',
                            fontWeight: 'bold',
                            border: `1px solid ${borderColor}`,
                            borderRadius: 4,
                            ':hover': {
                                borderColor: theme.palette.primary.main
                            }
                        }}
                        focusStyle={{
                            outline: 'none',
                            border: `2px solid ${theme.palette.primary.main}`
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button disableElevation fullWidth size="large" type="submit" variant="contained" disabled={isButtonDisabled}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Grid item container direction="column" alignItems="center" xs={12}>
                        <Typography
                            component={Link}
                            to="#"
                            variant="subtitle1"
                            sx={{ textDecoration: 'none' }}
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            Did not receive the email? Check your spam filter, or
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AnimateButton>
                        <Button disableElevation fullWidth size="large" variant="outlined" color="primary" onClick={() => resendOTP()}>
                            Resend Code
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
        </form>
    );
};
export default AuthEmailVerification;
