import { useEffect, useCallback, useState } from 'react';
import { get, post, patch, deleteData } from 'utils/axiosRequests';
import { globalAction, dispatch } from 'store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// ==============================|| CONFIG - LOCAL STORAGE ||============================== //

export default function useListing(url, secondaryURL) {
    const { total, currentPage, recordPerPage, searchStr, listingData, order, orderBy, currentData } = useSelector(
        (state) => state.listing
    );

    const location = useLocation();
    console.log(location, 'location');
    const query = new URLSearchParams(location.search);
    const queryPage = query.get('page');
    console.log(queryPage, 'queryPage');

    const [isLoading, setIsLoading] = useState(false);
    const [stateFilter, setStateFilter] = useState([]);
    const getListing = async () => {
        setIsLoading(true); // Loading Start
        const params = { page: currentPage, order, orderBy };
        if (searchStr) params.search = searchStr;
        if (stateFilter.length > 0) {
            params.filter = JSON.stringify(stateFilter);
        }
        params.limit = recordPerPage;
        await get(url, params)
            .then((response) => {
                setIsLoading(false); // Loading End
                dispatch(
                    globalAction('SET_LISTING', {
                        listingData: response.data,
                        total: response.total,
                        currentPage
                    })
                );
            })
            .catch((err) => console.log(err));
    };

    const setCurrentData = (currentData) => {
        dispatch(globalAction('SET_CURRENT_DATA', { currentData }));
    };

    useEffect(async () => {
        setCurrentData(undefined);
        await getListing();

        return () => {
            console.log('UnMount');
        };
    }, [currentPage, searchStr, order, orderBy, url, recordPerPage, stateFilter]);

    const setpage = (event, page) => {
        dispatch(globalAction('SET_CURRENT_PAGE', { currentPage: page + 1 }));
    };

    const setSearchStr = (str) => {
        dispatch(globalAction('SET_SEARCH_STR', { searchStr: str }));
    };

    const updateStatus = async (id, status) => {
        if (window.confirm(`Are you sure to ${status}`)) {
            patch(`${url}/${id}`, {
                status
            }).then((res) => {
                if (res.status === 'success') {
                    getListing();
                    // const dataIndex = listingData.findIndex((data) => data.id === id);

                    // listingData[dataIndex].status = status;
                    // const tempListingData = [...listingData];
                    // console.log(tempListingData);
                    // dispatch(globalAction('UPDATE_LISTING', { listingData: tempListingData }));
                }
            });
        }
    };

    const changeStatus = (event, id) => {
        const status = event.target.checked ? 'Active' : 'Inactive';
        updateStatus(id, status);
    };

    const deleteRow = async (e, id) => {
        if (window.confirm(`Are you sure to Delete`)) {
            console.log(secondaryURL, 'secondaryURL');
            deleteData(`${!secondaryURL ? url : secondaryURL}`, id).then((res) => {
                if (res.status === 'success') {
                    getListing();
                }
            });
        }
    };

    const changeOrder = useCallback((columnName) => {
        dispatch(globalAction('CHANGE_ORDER', { orderBy: columnName }));
    }, []);

    const resetListing = () => {
        dispatch(globalAction('RESET_LISTING'));
    };

    const rowsPerPage = (row) => {
        dispatch(globalAction('CHANGE_RECORD_PER_PAGE', { recordPerPage: row.target.value }));
    };

    return {
        total,
        currentPage,
        listingData,
        searchStr,
        recordPerPage,
        order,
        orderBy,
        isLoading,
        stateFilter,
        setpage,
        setSearchStr,
        changeStatus,
        deleteRow,
        setCurrentData,
        changeOrder,
        resetListing,
        rowsPerPage,
        getListing,
        setStateFilter
    };
}
