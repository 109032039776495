import { TableRow, TableCell } from '@mui/material';

function Loading({ colSpan }) {
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <div className="material-spinner" />
            </TableCell>
        </TableRow>
    );
}

export default Loading;
