// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconBox,
    IconBuildingCottage
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconBox,
    IconBuildingCottage
};

// ==============================|| Client PAGES MENU ITEMS ||============================== //

const clientPages = {
    id: 'client-pages',
    type: 'group',
    permissionID: 'All',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard',
            permissionID: 'All'
        },
        {
            id: 'Inquiries',
            title: <FormattedMessage id="Inquiry " />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/inquires',
            permissionID: 'Inquiry-read'
        },
        {
            id: 'Leads',
            title: <FormattedMessage id="Leads" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/leads',
            permissionID: 'Lead-read'
        },

        {
            id: 'clients',
            title: <FormattedMessage id="Clients" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/clients',
            permissionID: 'Client-read'
        }
        // {
        //     id: 'sales',
        //     title: <FormattedMessage id="sales" />,
        //     type: 'collapse',
        //     icon: icons.IconSettings,
        //     children: [
        //         {
        //             id: 'proforma',
        //             title: <FormattedMessage id="proforma" />,
        //             type: 'item',
        //             icon: icons.IconUsers,
        //             url: '/sales',
        //             permissionID: 'proforma-read'
        //         }
        //     ]
        // },
        // {
        //     id: 'staff',
        //     title: <FormattedMessage id="staff" />,
        //     type: 'item',
        //     icon: icons.IconUsers,
        //     url: '/staff',
        //     permissionID: 'Staff-read'
        // },

        // {
        //     id: 'hrm',
        //     title: <FormattedMessage id="hrm" />,
        //     type: 'collapse',
        //     icon: icons.IconUserSearch,
        //     children: [
        //         {
        //             id: 'attendance',
        //             title: <FormattedMessage id="attendance" />,
        //             type: 'item',
        //             icon: icons.IconCalendarEvent,
        //             url: '/hrm/attendance',
        //             permissionID: 'Attendance-read'
        //         },
        //         {
        //             id: 'leaves',
        //             title: <FormattedMessage id="leaves" />,
        //             type: 'item',
        //             icon: icons.IconCalendarStats,
        //             url: '/hrm/leaves',
        //             permissionID: 'Leave-read'
        //         }
        //     ]
        // }
    ]
};

export default clientPages;
