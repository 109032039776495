import axios from 'axios';
import { API_URL } from '../config';

const axiosServices = axios.create({
    baseURL: API_URL,
    timeout: 10000
});

axiosServices.interceptors.request.use((config) => {
    // Get the locale from local storage each time a request is made
    const storedConfig = window.localStorage.getItem('berry-config');
    const locale = storedConfig ? JSON.parse(storedConfig).locale : 'en';

    // Update the headers with the retrieved locale
    config.headers.locale = locale;

    return config;
});

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
