import * as React from 'react';
import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// material-ui
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    FormControlLabel,
    Switch,
    CardContent,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
    Fab,
    Menu,
    Stack,
    Chip,
    Typography,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconAdjustmentsHorizontal } from '@tabler/icons';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// assets
import SearchIcon from '@mui/icons-material/Search';

// project imports
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loading from './Loading';
import { deleteData, post } from 'utils/axiosRequests';
import AssignBulk from './AssignBulk';
import useAuth from 'hooks/useAuth';
// ==============================|| CUSTOMER LIST ||============================== //
const TableHeading = React.memo(
    ({
        chekBox,
        handleAllSelect,
        columns,
        changeOrder,
        listing,
        allSelectedId,
        orderBy,
        order,
        status,
        user,
        action,
        filterIcon,
        isSerialNumber
    }) => (
        <TableHead>
            <TableRow>
                {/* <TableCell>
                <TableSortLabel onClick={(e) => changeOrder('id')}>#</TableSortLabel>
            </TableCell> */}
                {isSerialNumber && (
                    <TableCell>
                        <TableSortLabel onClick={(e) => changeOrder('id')}>#</TableSortLabel>
                    </TableCell>
                )}
                {user?.user_type === 'Admin' && chekBox && (
                    <TableCell>
                        <Checkbox onClick={handleAllSelect} checked={allSelectedId?.length === listing?.listingData.length && true} />
                    </TableCell>
                )}
                {columns.map(({ label, id }) => (
                    <TableCell key={id} sortDirection={orderBy === id ? orderBy : false}>
                        <TableSortLabel active={orderBy === id} direction={orderBy === id ? order : 'asc'} onClick={(e) => changeOrder(id)}>
                            {label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {status && (
                    <TableCell>
                        <TableSortLabel>
                            <FormattedMessage id="Status" />
                        </TableSortLabel>
                    </TableCell>
                )}
                {action && (
                    <TableCell align="center">
                        <FormattedMessage id="Action" />
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
);

const Filter = React.memo(({ filter, filterIcon, listing }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateFilter = (type, column, chipValue, newValue) => {
        const newFilter = [...filter];
        const filterIndex = newFilter.findIndex((ele) => ele.column === column);

        switch (type) {
            case 'checkbox': {
                const chipIndex = newFilter[filterIndex].chip.findIndex((ele) => ele.value === chipValue);
                newFilter[filterIndex].chip[chipIndex].defaultValue = newValue;

                listing.setStateFilter(newFilter);
                break;
            }
            default:
                listing.setStateFilter(newFilter);
                break;
        }

        setAnchorEl(null);
    };

    return (
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            {filterIcon && (
                <Tooltip title="Filter" onClick={handleClick}>
                    <Fab color="primary" size="small" sx={{ boxShadow: 'none', ml: 1, width: 32, height: 32, minHeight: 32 }}>
                        <FilterAltOutlinedIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            )}
            <Menu
                id="menu-user-details-card"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {/* Filter Start */}
                {filter &&
                    filter.map((fData) => (
                        <div>
                            <Typography variant="subtitle1" sx={{ p: 1, pb: 0 }}>
                                {fData.label}
                            </Typography>

                            {fData.type === 'checkbox' && (
                                <Stack direction="row" spacing={1} sx={{ p: 1 }}>
                                    {fData.chip.map((chipData) => (
                                        <Chip
                                            label={chipData.label}
                                            style={
                                                chipData.defaultValue
                                                    ? {
                                                          color: theme.palette.primary.dark,
                                                          backgroundColor: theme.palette.primary.light
                                                      }
                                                    : {
                                                          color: theme.palette.grey.dark,
                                                          backgroundColor: theme.palette.grey.light
                                                      }
                                            }
                                            onClick={() => updateFilter(fData.type, fData.column, chipData.value, !chipData.defaultValue)}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </div>
                    ))}
                {/* Filter End */}
            </Menu>
        </Grid>
    );
});

const Listing = ({
    id,
    TableComponent,
    listing,
    columns,
    setAllSelectedId,
    allSelectedId,
    filter,
    status = true,
    CustomFilter = false,
    action = true,
    filterIcon = true,
    editAction = true,
    viewAction = true,
    deleteAction = true,
    isSerialNumber = true,
    chekBox = false
}) => {
    const serialNo = React.useCallback((index) =>
        listing.orderBy === 'id' && listing.order === 'asc'
            ? listing.total - listing.recordPerPage * (listing.currentPage - 1) - index
            : index + listing.recordPerPage * (listing.currentPage - 1) + 1
    );

    const colSpan = useMemo(() => {
        if (!status && !action) return columns.length + 1;
        if (!status || !action) return columns.length + 2;
        return columns.length + 3;
    }, []);
    // const [anchorEl, setAnchorEl] = useState(false);

    // const handleMenuClick = (id) => {
    //     setAnchorEl(id);
    // };

    // const handleMenuClose = () => {
    //     setAnchorEl(false);
    // };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event, id) => {
        setAnchorEl({ element: event.currentTarget, id });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // HANDLE BULK CREATOIN OF LEAD AND  INQUIRIES
    const handleAllSelect = () => {
        if (allSelectedId?.length === listing?.listingData.length) {
            setAllSelectedId([]);
        } else {
            const ids = listing?.listingData.map((item) => item.id);
            setAllSelectedId(ids);
        }
    };
    const { user } = useAuth();

    const handleBulkDelete = () => {
        if (window.confirm(`Are you sure to delete all selected data ?`)) {
            post(`/api/${id}/bulk-delete`, {
                bulkLeadId: allSelectedId
            }).then((res) => {
                if (res.status === 'success') {
                    setAllSelectedId([]);
                    window.location.reload();
                }
            });
        }
    };

    const [openAssgnModal, setOpenAssgnModal] = React.useState(false);

    const handleClickOpen = () => {
        setOpenAssgnModal(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssgnModal(false);
    };

    const customTableBody = (arr, Component) =>
        arr.length > 0 ? (
            arr.map((data, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {isSerialNumber && <TableCell>{serialNo(index)}</TableCell>}
                    {Component(data)}
                    {status && (
                        <TableCell>
                            <FormControlLabel
                                control={<Switch checked={data.status === 'Active'} onChange={(e) => listing.changeStatus(e, data.id)} />}
                            />
                        </TableCell>
                    )}
                    {action && (
                        <>
                            <TableCell align="center">
                                <IconButton onClick={(event) => handleMenuClick(event, data.id)}>
                                    <MoreVertIcon />
                                </IconButton>
                                {anchorEl && anchorEl.id === data.id && (
                                    <Menu
                                        anchorEl={anchorEl.element}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {editAction && (
                                            <MenuItem
                                                onClick={() => {
                                                    listing.setCurrentData(data);
                                                    handleMenuClose();
                                                }}
                                                component={Link}
                                                to={`/${id}/${data.id}/edit`}
                                                sx={{ gap: 1 }}
                                            >
                                                <EditTwoToneIcon /> <Typography>Edit</Typography>
                                            </MenuItem>
                                        )}
                                        {viewAction && (
                                            <MenuItem component={Link} to={`/${id}/${data.id}`} onClick={handleMenuClose} sx={{ gap: 1 }}>
                                                <VisibilityTwoToneIcon /> <Typography>View</Typography>
                                            </MenuItem>
                                        )}
                                        {deleteAction && (
                                            <MenuItem
                                                onClick={(e) => {
                                                    listing.deleteRow(e, data.id);
                                                    handleMenuClose();
                                                }}
                                                sx={{ gap: 1 }}
                                            >
                                                <DeleteTwoToneIcon /> <Typography>Delete</Typography>
                                            </MenuItem>
                                        )}
                                    </Menu>
                                )}
                            </TableCell>
                        </>
                    )}
                </TableRow>
            ))
        ) : (
            <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                    <FormattedMessage id="No Record Found!" />
                </TableCell>
            </TableRow>
        );
    const intl = useIntl();
    return (
        <>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', gap: '15px' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => listing.setSearchStr(e.target.value)}
                            placeholder={intl.formatMessage({ id: 'Search here' })}
                            value={listing.searchStr}
                            size="small"
                        />

                        <Grid item xs={12} sm={6}>
                            {CustomFilter}
                        </Grid>
                    </Grid>
                    {user?.user_type === 'Admin' && allSelectedId && allSelectedId?.length > 0 && (
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                                <Button variant="contained" onClick={handleClickOpen}>
                                    Bulk Assign
                                </Button>
                                <Button variant="outlined" onClick={handleBulkDelete}>
                                    Bulk Delete
                                </Button>
                            </Stack>
                        </Grid>
                    )}
                    {listing.stateFilter.length > 0 ? (
                        <Filter listing={listing} filter={listing.stateFilter} filterIcon={filterIcon} />
                    ) : (
                        <Filter listing={listing} filter={filter} filterIcon={filterIcon} />
                    )}
                </Grid>
                {openAssgnModal && (
                    <AssignBulk
                        openAssgnModal={openAssgnModal}
                        handleCloseAssignModal={handleCloseAssignModal}
                        setOpenAssgnModal={setOpenAssgnModal}
                        allSelectedId={allSelectedId}
                        setAllSelectedId={setAllSelectedId}
                        id={id}
                    />
                )}
            </CardContent>

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeading
                        listing={listing}
                        allSelectedId={allSelectedId}
                        columns={columns}
                        chekBox={chekBox}
                        user={user}
                        handleAllSelect={handleAllSelect}
                        changeOrder={listing.changeOrder}
                        order={listing.order}
                        orderBy={listing.orderBy}
                        status={status}
                        action={action}
                        filterIcon={filterIcon}
                        isSerialNumber={isSerialNumber}
                    />
                    <TableBody>
                        {listing.isLoading ? (
                            <Loading colSpan={colSpan} />
                        ) : (
                            customTableBody(listing.listingData, (data) => TableComponent(data))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100]}
                component="div"
                count={listing.total}
                rowsPerPage={listing.recordPerPage}
                page={listing.currentPage - 1}
                onPageChange={listing.setpage}
                onRowsPerPageChange={listing.rowsPerPage}
            />
        </>
    );
};

export default Listing;
