// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography
            variant="subtitle2"
            sx={{ color: '#ffffff' }}
            component={Link}
            href="https://imperialit.in"
            target="_blank"
            underline="hover"
        >
            Designed and Developed by ImperialIT
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#ffffff' }} component={Link} href="#" target="_blank" underline="hover">
            &copy; Carton
        </Typography>
    </Stack>
);

export default AuthFooter;
