// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconChartBar,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp,
    ManageSearch
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconChartBar,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    permissionID: 'All',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard',
            permissionID: 'All'
        },
        {
            id: 'Inquiries',
            title: <FormattedMessage id="Inquiry " />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/inquires',
            permissionID: 'Inquiry-read'
        },
        {
            id: 'Leads',
            title: <FormattedMessage id="Leads" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/leads',
            permissionID: 'leads-read'
        },

        {
            id: 'clients',
            title: <FormattedMessage id="Clients" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/clients',
            permissionID: 'Client-read'
        },

        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/calendar',
            permissionID: 'calendar-read'
        },
        {
            id: 'staff',
            title: <FormattedMessage id="staff" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/staff',
            permissionID: 'Staff-read'
        },
        // {
        //     id: 'sales',
        //     title: <FormattedMessage id="sales" />,
        //     type: 'collapse',
        //     icon: icons.IconSettings,
        //     children: [
        //         {
        //             id: 'proforma',
        //             title: <FormattedMessage id="proforma" />,
        //             type: 'item',
        //             icon: icons.IconUsers,
        //             url: '/sales',
        //             permissionID: 'proforma-read'
        //         }
        //     ]
        // },
        {
            id: 'hrm',
            title: <FormattedMessage id="hrm" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            children: [
                // {
                //     id: 'Hiring',
                //     title: <FormattedMessage id="Hiring " />,
                //     type: 'item',
                //     icon: icons.IconCalendarEvent,
                //     url: '/hrm/Hiring ',
                //     permissionID: 'Hiring -read'
                // },
                {
                    id: 'roles-departments',
                    title: <FormattedMessage id="roles-departments" />,
                    type: 'collapse',
                    icon: icons.IconSubtask,
                    children: [
                        {
                            id: 'roles',
                            title: <FormattedMessage id="roles" />,
                            type: 'item',
                            icon: icons.IconUser,
                            url: '/setting/roles',
                            permissionID: 'Role-read'
                        },
                        {
                            id: 'departments',
                            title: <FormattedMessage id="departments" />,
                            type: 'item',
                            icon: icons.IconBuilding,
                            url: '/setting/departments',
                            permissionID: 'Department-read'
                        },
                        {
                            id: 'documents-types',
                            title: <FormattedMessage id="documents-types" />,
                            type: 'item',
                            icon: icons.IconBooks,
                            url: '/setting/documents-types',
                            permissionID: 'documentsTypes-read'
                        }
                    ]
                },
                {
                    id: 'staff-management',
                    title: <FormattedMessage id="staff-management" />,
                    type: 'collapse',
                    icon: icons.IconUsers,
                    children: [
                        {
                            id: 'shift',
                            title: <FormattedMessage id="shift" />,
                            type: 'item',
                            icon: icons.IconClock,
                            url: '/setting/shift',
                            permissionID: 'Shift-read'
                        },
                        {
                            id: 'leave-type',
                            title: <FormattedMessage id="leave-type" />,
                            type: 'item',
                            icon: icons.IconCalendarMinus,
                            url: '/setting/leave-type',
                            permissionID: 'LeaveType-read'
                        }
                    ]
                },
                {
                    id: 'holiday',
                    title: <FormattedMessage id="holiday" />,
                    type: 'item',
                    icon: icons.IconConfetti,
                    url: '/setting/holidays',
                    permissionID: 'Holiday-read'
                },
                {
                    id: 'attendance',
                    title: <FormattedMessage id="attendance" />,
                    type: 'item',
                    icon: icons.IconCalendarEvent,
                    url: '/hrm/attendance',
                    permissionID: 'Attendance-read'
                },

                {
                    id: 'leaves',
                    title: <FormattedMessage id="leaves" />,
                    type: 'item',
                    icon: icons.IconCalendarStats,
                    url: '/hrm/leaves',
                    permissionID: 'Leave-read'
                },
                {
                    id: 'announcement',
                    title: <FormattedMessage id="announcement" />,
                    type: 'item',
                    icon: icons.IconBell,
                    url: '/hrm/announcements',
                    permissionID: 'Announcement-read'
                }
            ]
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'status',
                    title: <FormattedMessage id="Status" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/setting/status',
                    permissionID: 'Status-read'
                },
                {
                    id: 'category',
                    title: <FormattedMessage id="Category" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/settings/category',
                    permissionID: 'category-read'
                },
                {
                    id: 'source',
                    title: <FormattedMessage id="Source" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/setting/source',
                    permissionID: 'source-read'
                },
                {
                    id: 'desk',
                    title: <FormattedMessage id="desk" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/setting/desk',
                    permissionID: 'desk-read'
                }
                // {
                //     id: 'job-role',
                //     title: <FormattedMessage id="Job-role" />,
                //     type: 'item',
                //     icon: icons.IconApiApp,
                //     url: '/setting/job-role',
                //     permissionID: 'job-role-read'
                // }
            ]
        }
    ]
};

export default pages;
