import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { get, post } from 'utils/axiosRequests';

export default function AssignBulk({ handleCloseAssignModal, openAssgnModal, allSelectedId, id, setAllSelectedId }) {
    const [specialisationId, setSpecialisationId] = useState([]);
    const [staffValue, setStaffValue] = useState([]);
    const [allStaffList, setAllStaffList] = useState([]);

    const handleStaffValue = (e) => {
        const array = e && e.map((values) => values);
        setSpecialisationId(array);
        setStaffValue(e);
    };

    console.log(specialisationId, 'specialisationId');
    console.log(staffValue, 'staffValue');

    useEffect(() => {
        get(`/api/staffs`).then((res) => {
            if (res.status === 'success') {
                setAllStaffList(res.data);
            }
        });
    }, []);

    const handleAssign = () => {
        post(`/api/${id}/assign-bulk`, {
            id: allSelectedId,
            staffId: staffValue
        }).then((res) => {
            if (res.status === 'success') {
                handleCloseAssignModal();
                setAllSelectedId([]);
                window.location.reload();
            }
        });
    };

    return (
        <Dialog
            open={openAssgnModal}
            onClose={handleCloseAssignModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <Stack spacing={2} sx={{ p: 4 }}>
                <InputLabel id="test-select-label">Select the Staffs</InputLabel>
                <Select multiple fullWidth label="Select the Staffs" value={staffValue} onChange={(e) => handleStaffValue(e.target.value)}>
                    {allStaffList.map((staff) => (
                        <MenuItem key={staff?.id} value={staff?.id}>
                            {staff?.first_name} {staff?.last_name} ({staff?.deskDetails?.name})
                        </MenuItem>
                    ))}
                </Select>
            </Stack>
            <DialogActions sx={{ justifyContent: 'flex-start', pl: 4 }}>
                <Button variant="contained" sx={{ mt: 2 }} onClick={handleAssign}>
                    Assign
                </Button>
            </DialogActions>
        </Dialog>
    );
}
