import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Error from 'views/pages/maintenance/Error';

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const QuotationPage = Loadable(lazy(() => import('views/landing/QuotationPage')));
const SampleFormPage = Loadable(lazy(() => import('views/landing/SampleFormPage')));

const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/quotation',
            element: <QuotationPage />
        },
        {
            path: '/pages/sample',
            element: <SampleFormPage />
        },
        {
            path: '/pages/error',
            element: <MaintenanceError />
        },
        {
            path: '/pages/under-construction',
            element: <MaintenanceUnderConstruction />
        },
        {
            path: '*',
            element: <Error />
        }
    ]
};

export default AuthenticationRoutes;
