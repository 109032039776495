import { useEffect, useState } from 'react';
import { put, post, patch, get } from 'utils/axiosRequests';
import { globalAction, dispatch } from 'store';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
// ==============================|| CONFIG - LOCAL STORAGE ||============================== //

export default function useHandleForm(
    url,
    pageBehaviour = 'Add',
    formikInitialValues,
    validationSchema,
    changeFormSubmitValue = null,
    handleResponce = null,
    handleSuccessResponce = null,
    redirectURL = null
) {
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { currentPage, recordPerPage, searchStr, currentData } = useSelector((state) => state.listing);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (changeFormSubmitValue !== null) values = changeFormSubmitValue(values);
            if (pageBehaviour === 'Add') {
                addData(values, resetForm);
            } else {
                updateData(values);
            }
        }
    });

    const getCurrentDataByID = () => {
        setIsLoading(true); // Loading Start
        get(url)
            .then((response) => {
                dispatch(
                    globalAction('SET_CURRENT_DATA', {
                        currentData: response.data
                    })
                );
                setIsLoading(false); // Loading End
            })
            .catch(() => {
                setIsLoading(false); // Loading End
            });
    };

    useEffect(() => {
        if (currentData && pageBehaviour !== 'Add') {
            Object.keys(formikInitialValues).forEach((key) => {
                formik.setFieldValue(key, currentData[key]);
            });
        } else if (!currentData && pageBehaviour !== 'Add') {
            getCurrentDataByID();
        }
    }, [currentData]);

    const setCurrentData = (currentData) => {
        dispatch(
            globalAction('SET_CURRENT_DATA', {
                currentData
            })
        );
    };

    function addData(value, resetForm) {
        setIsLoading(true); // Loading Start
        post(url, value)
            .then((res) => {
                if (res?.type === 'validationError') {
                    res.errors.forEach((error) => {
                        formik.setFieldError(error.param, error.msg);
                    });
                } else {
                    resetForm();
                    if (redirectURL) {
                        navigate(redirectURL);
                    } else if (redirectURL === null) {
                        navigate(-1);
                    }
                }
                if (handleSuccessResponce && res?.status === 'success') handleSuccessResponce(res);
                return res;
            })
            .catch((err) => {
                if (err?.type === 'validationError') {
                    err.errors.forEach((error) => {
                        formik.setFieldError(error.param, error.msg);
                    });
                }
                return err;
            })
            .finally((result) => {
                setIsLoading(false); // Loading End
                if (handleResponce) handleResponce(result);
            });
    }

    function updateData(value) {
        setIsLoading(false); // Loading Start
        put(`${url}`, value)
            .then((res) => {
                if (res?.type === 'validationError') {
                    res.errors.forEach((error) => {
                        formik.setFieldError(error.param, error.msg);
                    });
                } else {
                    console.log('Form Updated');
                    if (redirectURL) {
                        navigate(redirectURL);
                    } else if (redirectURL === null) {
                        navigate(-1);
                    }
                }
                if (handleSuccessResponce && res?.status === 'success') handleSuccessResponce();
                return res;
            })
            .catch((err) => {
                if (err?.type === 'validationError') {
                    err.errors.forEach((error) => {
                        console.log(error);
                        formik.setFieldError(error.param, error.msg);
                    });
                }
                return err;
            })
            .finally((result) => {
                setIsLoading(false); // Loading End
                if (handleResponce) handleResponce(result);
            });
    }

    return [formik, isLoading, currentData, currentPage, searchStr, recordPerPage, setCurrentData];
}
